import Images from '../Utils/images'

export default function NoNotification() {
  return (
    <div className={`success no_notification`}>
      <img src={Images.NO_NOTIFICATIONS} alt={'No Notifications Yet'} />
      <h3>No Notifications Yet</h3>
      <p style={{ maxWidth: '300px' }}>
        You have no notifications right now. Come back later
      </p>
    </div>
  )
}
