import { createSlice } from '@reduxjs/toolkit'
import endPoints from '../api/endpoint'
import store from './store'
import { deleteApiCall, getApiCall } from '../api/methods'
import { notify } from '../Utils/toastify'
import { CommonState } from '../types'
import { startLoading, stopLoading } from './loaders.slice'
import { MediaUploadLoader } from '../types/loadertypes'

const initialState: CommonState = {
  countryList: [],
  departmentList: [],
  categories: [],
  loading: false,
  page: 1,
  searchedLocations: [],
  filterapplied: false,
  presignedSignedUrl:[]
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setData: (state, action) => {
      const {
        payload: { type, value },
      }: any = action
      if (type === 'country') {
        state.countryList = value
      } else if (type === 'department') {
        state.departmentList = value
      } else if (type === 'categories') {
        state.categories = value
      }
    },
    updatePage: (state, action) => {
      const { payload }: any = action
      state.page = payload
    },
    setCommonDataLoader: (state, action) => {
      const { payload }: any = action
      state.loading = payload
    },
    updateSearchedLocations: (state, action) => {
      const { payload }: any = action
      state.searchedLocations = payload
    },
    setFilterApplied: (state, action) => {
      state.filterapplied = action.payload
    },
    setPresignedUrl: (state, action) => {
      const { payload }: any = action
      state.presignedSignedUrl = payload
    },
  },
})

export const { setData, setCommonDataLoader, updatePage, updateSearchedLocations, setFilterApplied, setPresignedUrl  } = commonSlice.actions

export default commonSlice.reducer

export const getAndSetData = (type: string, query: string = '') => {
  const apiEndpoint =
    type === 'country'
      ? endPoints.common.countryList
      : type === 'department'
      ? endPoints.common.deparmentList
      : endPoints.common.categories

  store.dispatch(setCommonDataLoader(true))
  getApiCall(
    `${apiEndpoint}${query}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        store.dispatch(setData({ type: type, value: data.data }))
      }
      store.dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      console.log(e)
      store.dispatch(setCommonDataLoader(false))
      notify(`Error while fetching ${type} list`, 'error')
    },
    type === 'country'
      ? { Authorization: `Basic ${btoa('reel:reel@123')}` }
      : {}
  )
}
export const getPresignedUrl = () => {
  const apiEndpoint = endPoints.getPresignedUrl

  store.dispatch(setCommonDataLoader(true))
  getApiCall(
    `${apiEndpoint}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        store.dispatch(setPresignedUrl(data.data))
      }
      store.dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      console.log(e)
      store.dispatch(setCommonDataLoader(false))
      notify(`Error while fetching deparment list`, 'error')
    }
  )
}
export const updatePaginationPage = (page: number) => {
  const { dispatch } = store
  dispatch(updatePage(page))
}

export async function fetchLocationByText(location: string) {
  const { dispatch } = store
  console.log(location, 'loc')

  const apiKey = process.env.GOOGLE_API_KEY
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apiKey}`
  )
  response.json().then((data) => {
    if (data.results) {
      dispatch(updateSearchedLocations(data.results))
      console.log(data)
    }
  })
}

export const uploadFilesToBE = async (
  file: File,
  onProgress?: (progressMap: Record<string, number>) => void,
): Promise<UploadedFile> => {
  try {
    console.log(file,'FILES----')
    let url: string = '';
    let progressMap: Record<string, number> = {};
    const authToken = store.getState().auth.token;
    const formData = new FormData();
    formData.append('file', file);

    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(
        'POST',
        `${process.env.API_URL}${endPoints.uploadFileBE}`,
        true,
      );
      xhr.setRequestHeader(
        'timezone',
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
      xhr.setRequestHeader('language', 'en');
      xhr.setRequestHeader(
        'offset',
        `${new Date().getTimezoneOffset() * 60 * 1000}`,
      );
      xhr.setRequestHeader('authorization', `Bearer ${authToken}`);
      xhr.setRequestHeader('platform', `3`);
      xhr.setRequestHeader('api_key', '1234');
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          progressMap = {
            ...progressMap,
            [file.name]: Math.round((event.loaded / event.total) * 100),
          };
          onProgress(progressMap);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const response = JSON.parse(xhr.responseText);
          url = response.data;
          resolve(response);
        } else {
          reject(new Error(`File upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () =>
        reject(new Error('Upload failed due to a network error'));

      xhr.send(formData);
    });

    return { url, name: file.name };
  } catch (error) {
    console.error('Upload failed:', error);
    throw new Error('Upload failed');
  }
};

export const getPresignedUrls = async (
  queryNames: string,
): Promise<{ preSignedUrl: string; baseUrl: string; filename: string }[]> => {
  const { dispatch } = store;

  return new Promise((resolve, reject) => {
    dispatch(startLoading(MediaUploadLoader));
    getApiCall(
      `${endPoints.signedUrls}?${queryNames}`,
      (response: any) => {
        const { data } = response;
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject('No presigned URL or base URL found in the response');
        }
      },
      (error: any) => {
        console.error('Error while fetching presigned URL:', error);
        dispatch(stopLoading(MediaUploadLoader));
        reject('Error while fetching presigned URL');
      },
    );
  });
};
