import Popover from '@mui/material/Popover';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import Images from '../Utils/images';
import CustomButton from './CustomButton';
import { updateDeviceTokenForSafari } from '../redux/authSlice';

export default function PushNotification() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [permissionData, setPermissionData] = useState<any>(
    window.safari.pushNotification.permission(`${process.env.WEB_PUSH_ID}`),
  );
  const [showIcon, setShowIcon] = useState<boolean>(
    window.safari.pushNotification.permission(`${process.env.WEB_PUSH_ID}`)
      .permission === 'default',
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowIcon(false);
  };
  // safari permission
  const handleSafariPermission = () => {
    checkRemotePermission(permissionData);
  };
  // remote permission
  const checkRemotePermission = function (permissionD: any) {
    if (permissionD.permission === 'default') {
      // This is a new web service URL and its validity is unknown.
      try {
        window.safari.pushNotification.requestPermission(
          `${process.env.API_URL_NOTIFICATION}`, // The web service URL.
          `${process.env.WEB_PUSH_ID}`, // The Website Push ID.
          {}, // Data that you choose to send to your server to help you identify the user.
          checkRemotePermission, // The callback function.
        );
      } catch (e) {
        console.log('error', e);
      }
    } else if (permissionD.permission === 'denied') {
      setShowIcon(false);
      setPermissionData(permissionD);
      handleClose();

      // The user said no.
    } else if (permissionD.permission === 'granted') {
      setShowIcon(false);
      setPermissionData(permissionD);
      updateDeviceTokenForSafari(permissionD.deviceToken);
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <div className="pushNotification">
      {showIcon && (
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          className="push_button"
          disableRipple
        >
          <img
            src={Images.PUSH_NOTIFICATION}
            alt={'Manage Push Notifications'}
          />
        </IconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 280,
          horizontal: 'right',
        }}
        className="notification_popover"
      >
        <div className="push_head">
          <h3>{'Manage Push Notifications'}</h3>
          <IconButton onClick={handleClose}>
            <img src={Images.CLOSE} alt={'Manage Push Notifications'} />
          </IconButton>
        </div>
        <div className="push_body">
          <p>
            The website “Reeltrak” would like to send you push notification in
            notification center.
          </p>
        </div>
        <div className="push_bottom">
          <CustomButton
            onClick={() => {
              handleClose();
            }}
            size="large"
            variant="outlined"
            text={'Reject'}
            showIcon={false}
            width="100%"
            type="button"
            id="reject"
            loading={false}
          />
          <CustomButton
            onClick={() => {
              handleSafariPermission();
            }}
            size="large"
            variant="contained"
            text={'Allow'}
            showIcon={false}
            width="100%"
            type="button"
            id="allow"
            loading={false}
          />
        </div>
      </Popover>
    </div>
  );
}
