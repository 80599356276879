import { createSlice } from '@reduxjs/toolkit'
import { BannerSortKeys, ConfigurationSortKeys } from '../../types'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'

interface Client {
  studio: any
  productions: any
  department: any,
  company:any,
  university:any,
  degree:any,
  allDepartments: any
  categories: any
  roles: any
  loading: boolean
  error: boolean
  searchTerm?: string
  fromDate?: any
  toDate?: any
  page: number
  selectedValue:any,
  sortBy: ConfigurationSortKeys;
  sortOrder: 1 | -1;

}

const initialState: Client = {
  studio: {},
  productions: {},
  department: {},
  company:{},
  university:{},
  degree:{},
  allDepartments: {},
  categories: {},
  roles: {},
  loading: false,
  error: false,
  searchTerm: "",
  fromDate: 0,
  toDate: 0,
  page:1,
  selectedValue: "",
  sortBy: ConfigurationSortKeys.categoryName,
  sortOrder: 1,
}
const clientManagement: any = createSlice({
  name: 'client management',
  initialState,
  reducers: {
    updateClientManagement: (state, action: any) => {
      return { ...state, ...action.payload }
    },
    resetClientManagement: (state, action: any) => {
      return initialState
    },
    updateSortKey: (state, action) => {
      const { payload }: any = action;
      state.sortBy = payload;
    },
    updateSortMode: (state, action) => {
      const { payload }: any = action;
      state.sortOrder = payload;
    },
  },
})

export const {
  updateClientManagement,
  resetClientManagement,
  updateSortKey,
  updateSortMode
} = clientManagement.actions

export default clientManagement.reducer
