import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'
import {
  VendorModel,
  VendorData,
  VendorListingQueryParameters,
  VendorDetails,
  VendorRejectCase,
  VendorBlockDelete,
  VendorListingFilters,
} from './vendor.type'

const initialState: VendorModel = {
  vendorData: {
    data: [],
    total: 0,
    pageNo: 0,
    totalPage: 0,
    nextHit: 0,
    limit: 0,
    status: '',
    businessType: '',
  },

  requestData: {
    data: [],
    total: 0,
    pageNo: 0,
    totalPage: 0,
    nextHit: 0,
    limit: 0,
    status: '',
    businessType: '',
  },

  vendorListingQueryParameters: {
    pageNo: 1,
    limit: 10,
    sortOrder: 1,
    isApproved: true,
    sortBy: 'businessInfo.businessName',
    searchKey: '',
    fromDate: 0,
    toDate: 0,
  },

  vendorDetails: {
    businessInfo: {
      addressLineOne: '',
      addressLineTwo: '',
      businessLocation: '',
      businessName: '',
      businessType: '',
      city: '',
      country: '',
      department: [],
      dob: '',
      state: '',
      zipCode: '',
    },
    documentInfo: {
      countryOfIssue: '',
      dateOfExp: '',
      documentNumber: '',
    },
    email: '',
    firstName: '',
    formNextStep: '',
    lastName: '',
    middleName: '',
    status: '',
  },

  vendorListingFilters: {
    fromDate: 0,
    toDate: 0,
    status: 'ACTIVE',
    businessType: '',
  },

  vendorBlockDelete: {
    vendorId: '',
    status: '',
  },

  vendorRejectCase: {
    rejectReason: '',
    rejectDescription: '',
  },

  productions: {},
  department: {},
  categories: {},
  roles: {},
  loading: false,
  error: false,
}
const vendorSlice = createSlice({
  name: 'vendor-management',
  initialState,
  reducers: {
    updateVendorManagement: (
      state: VendorModel,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload
    },

    setVendorsListData: (
      state: VendorModel,
      action: PayloadAction<VendorData>
    ) => {
      state.vendorData = action.payload
    },

    setReqestsListData: (
      state: VendorModel,
      action: PayloadAction<VendorData>
    ) => {
      state.requestData = action.payload
    },

    setVendorsListingQueryParameters: (
      state: VendorModel,
      action: PayloadAction<VendorListingQueryParameters>
    ) => {
      state.vendorListingQueryParameters = action.payload
    },

    setVendorDetails: (
      state: VendorModel,
      action: PayloadAction<VendorDetails>
    ) => {
      state.vendorDetails = action.payload
    },

    setVendorRejectReason: (
      state: VendorModel,
      action: PayloadAction<VendorRejectCase>
    ) => {
      state.vendorRejectCase = action.payload
    },

    setBlockDeleteVendor: (
      state: VendorModel,
      action: PayloadAction<VendorBlockDelete>
    ) => {
      state.vendorBlockDelete = action.payload
    },

    setVendorFilters: (
      state: VendorModel,
      action: PayloadAction<VendorListingFilters>
    ) => {
      state.vendorListingFilters = action.payload
    },
  },
})

export const {
  setVendorsListData,
  setReqestsListData,
  updateVendorManagement,
  setVendorRejectReason,
  setVendorsListingQueryParameters,
  setVendorDetails,
  setBlockDeleteVendor,
  setVendorFilters,
  //   resetVendorManagement,
} = vendorSlice.actions

export default vendorSlice.reducer
