import AWS from 'aws-sdk'
import {
  decryptDataWithKey,
  generateRandomString,
  keyPhrase,
} from './helperFns'
import { s3Url } from './constantData'
import { notify } from './toastify'
import endPoints from '../api/endpoint'
import store from '../redux/store'
import { getApiCall } from '../api/methods'
import { setCommonDataLoader, setPresignedUrl } from '../redux/commonSlice'

export const S3_BUCKET = process.env.S3_BUCKET_NAME
const REGION = process.env.S3_REGION
export const AWS_FOLDER_PATH = process.env.FOLDER_PATH
AWS.config.update({
  accessKeyId: decryptDataWithKey(process.env.AWS_ACCESS_KEY_ID, keyPhrase),
  secretAccessKey: decryptDataWithKey(
    process.env.AWS_SECRET_ACCESS_KEY,
    keyPhrase
  ),
})

export const reelBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

interface UploadedFile {
  url: string
  name: string
}
export const getPresignedUrl = async (
  fileName: string
): Promise<{ preSignedUrl: string; baseUrl: string }> => {
  const apiEndpoint = endPoints.getPresignedUrl

  return new Promise((resolve, reject) => {
    store.dispatch(setCommonDataLoader(true))

    getApiCall(
      `${apiEndpoint}?filename=${fileName}`,
      (response: any) => {
        const { data } = response
        if (data && data.data && data.data.preSignedUrl && data.data.baseUrl) {
          store.dispatch(setPresignedUrl(data.data.preSignedUrl))
          resolve({
            preSignedUrl: data.data.preSignedUrl,
            baseUrl: data.data.baseUrl,
          })
        } else {
          reject('No presigned URL or base URL found in the response')
        }
        store.dispatch(setCommonDataLoader(false))
      },
      (error: any) => {
        console.error('Error while fetching presigned URL:', error)
        store.dispatch(setCommonDataLoader(false))
        reject('Error while fetching presigned URL')
      }
    )
  })
}
// export const uploadToS3 = async (file: File): Promise<UploadedFile> => {
//   return new Promise((resolve, reject) => {
//     const s3 = reelBucket
//     const key = AWS_FOLDER_PATH + `${Date.now()}_${file.name}`
//     const params = {
//       Bucket: S3_BUCKET || '',
//       Key: key,
//       Body: file,
//       ACL: 'public-read', // Set the ACL as needed
//     }

//     const options = {
//       partSize: 5 * 1024 * 1024, // 5MB parts
//       queueSize: 4, // 4 concurrent uploads
//     }

//     const upload = s3.upload(params, options)

//     upload.on('httpUploadProgress', (progress) => {
//       const percentage = Math.round((progress.loaded / progress.total) * 100)
//       // setUploadProgress(percentage)
//     })

//     upload
//       .promise()
//       .then((data) => {
//         // console.log('data url s3', data)
//         resolve({ url: data.Location, name: file.name })

//         // setUploadProgress(null) // Reset progress after successful upload
//       })
//       .catch((error) => {
//         console.log('error', error)
//         reject(error)
//         // setUploadProgress(null) // Reset progress after failed upload
//       })
//   })
// }
export const uploadToS3 = async (
  file: File,
  setUploadProgress?: (progress: number) => void
): Promise<UploadedFile> => {
  try {
    // Step 1: Get the presigned URL and baseUrl
    console.log(file,'FILENAME-->')
    const { preSignedUrl, baseUrl } = await getPresignedUrl(file.name)  
    console.log('Presigned URL:', preSignedUrl)
    console.log('Base URL:', baseUrl)

    // Step 2: Convert the file to a Blob
    const fileBlob = new Blob([file], { type: file.type })

    // Step 3: Create XMLHttpRequest to track upload progress
    return new Promise<UploadedFile>((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', preSignedUrl, true)
      xhr.setRequestHeader('Content-Type', file.type)

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && setUploadProgress) {
          const progress = Math.round((event.loaded / event.total) * 100)
          setUploadProgress(progress)
        }
      }

      // Handle upload success
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const uploadedFileUrl = `${baseUrl}${file.name}`
          resolve({ url: uploadedFileUrl, name: file.name })
        } else {
          reject(new Error(`File upload failed with status: ${xhr.status}`))
        }
      }

      // Handle errors
      xhr.onerror = (e: any) => {
        console.log('Error =>', e)
        reject(new Error('Upload failed due to network error.'))
      }

      // Send the file
      xhr.send(fileBlob)
    })
  } catch (error) {
    console.error('Upload failed:', error)
    throw new Error('Upload failed')
  }
}
// upload func as Vendor pannel
export const commonUploadFn = (
  file: File,
  setProgress: undefined | any = undefined,
  callBack: undefined | ((url: string | null) => void) = undefined
) => {
  const name = generateRandomString(5) + file.name.replaceAll(' ', '-')
  const fileURl = AWS_FOLDER_PATH + name
  const params = {
    ACL: 'public-read',
    Body: file,
    Bucket: S3_BUCKET || '',
    Key: fileURl,
    // keyPrefix: keyPrefix,
  }

  const upload = reelBucket
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
      setProgress && setProgress(Math.round((evt.loaded / evt.total) * 100))
    })
  upload.send((err, data) => {
    if (data && callBack) {
      callBack(s3Url + fileURl)
    }
    if (err) {
      notify('Error while uploading, Please try again.', 'error')
      callBack && callBack(null)
    }
  })
  return upload
}


export const uploadToS3WithPresigedUrl = async (
  file: File,
  url: { preSignedUrl: string; baseUrl: string },
  onProgress?: (progress: number) => void,
): Promise<{ url: string; name: string }> => {
  try {
    // Step 2: Define headers for the PUT request
    const headers = {
      'Content-Type': file.type,
    };
    const { preSignedUrl, baseUrl } = url;
    // Step 3: Convert the file to a Blob (optional as `File` extends `Blob`)
    const fileBlob = new Blob([file], { type: file.type });

    // Step 4: Use XMLHttpRequest to upload the file and track progress
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', preSignedUrl, true);
      // Set headers
      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value as string);
      });

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const progress = Math.round((event.loaded / event.total) * 100);
          onProgress(progress);
        }
      };

      // Handle completion
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const uploadedFileUrl = `${baseUrl}${file.name}`;
          resolve({ url: uploadedFileUrl, name: file.name });
        } else {
          reject(new Error(`File upload failed with status: ${xhr.status}`));
        }
      };

      // Handle errors
      xhr.onerror = () =>
        reject(new Error('Upload failed due to a network error'));

      // Send the file
      xhr.send(fileBlob);
    });
  } catch (error) {
    console.error('Upload failed:', error);
    throw new Error('Upload failed');
  }
};