import { useDispatch, useSelector } from 'react-redux'
import {
  fetchNoticationList,
  markAllNotificatioRead,
  markNotificatioRead,
  resetList,
  selectNotificationData,
  selectNotificationLoading,
} from '../redux/notification.slice'
import { Box, IconButton, Menu } from '@mui/material'
import Images from '../Utils/images'
import { AIT } from '../Utils/Localization'
import { useEffect, useRef, useState } from 'react'
import CustomButton from './CustomButton'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { NOTIFICATION_TYPES } from '../Utils/constantData'
import { useNavigate } from 'react-router-dom'
import LoadingNotification from './LoadingNotification'
import NoNotification from './NoNotification'
import { useSocket } from '../Pages/Chats/SocketContext'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const InAppNotification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector(selectNotificationLoading)
  const data = useSelector(selectNotificationData)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  })
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const openPopUp = Boolean(anchorEl)
  const boxRef = useRef<HTMLUListElement>(null) // Ul Ref
  const prevScrollPosition = useRef<number>(0)
  const handleClickNotice = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseNotice = () => {
    setAnchorEl(null)
  }
  const socketClient = useSocket()

  const handleScroll = () => {
    if (boxRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = boxRef.current

      if (
        Math.ceil(scrollTop + clientHeight) >= scrollHeight &&
        loading !== 'pending' &&
        hasMore
      ) {
        data?.data && data?.data.pageNo && setPageNumber(data?.data.pageNo + 1)
      }
      prevScrollPosition.current = scrollTop
    }
  }

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = prevScrollPosition.current
    }
    if (data && data?.data && data?.data.totalPage) {
      setHasMore(data?.data.totalPage >= pageNumber)
    }
  }, [data?.data.pageNo])

  useEffect(() => {
    if (loading == 'idle' && hasMore) {
      dispatch(fetchNoticationList({ page: pageNumber, pageSize: 5 }))
    }
  }, [loading])

  useEffect(() => {
    if (hasMore && loading !== 'idle' && loading !== 'pending') {
      dispatch(fetchNoticationList({ page: pageNumber, pageSize: 5 }))
    }
  }, [dispatch, pageNumber, hasMore])

  useEffect(() => {
    setTimeout(() => {
      if (boxRef.current) {
        boxRef.current.addEventListener('scroll', handleScroll) // Attach event listener to the referenced element
        return () => {
          if (boxRef.current) {
            boxRef.current.removeEventListener('scroll', handleScroll) // Remove event listener when component unmounts
          }
        }
      }
    }, 200)
  }, [handleScroll, boxRef.current, openPopUp])

  const handleNotificationClick = (n: any) => {
    handleCloseNotice()
    if (!n.isRead) {
      setPageNumber(1)
      dispatch(resetList())
      markNotificatioRead(n._id, () => {
        dispatch(fetchNoticationList({ page: pageNumber, pageSize: 5 }))
        handleRedirection(n)
      })
    } else {
      handleRedirection(n)
    }
  }

  const handleRedirection = (n: any) => {
    // console.log('nnnn=>>>', n)
    if (
      n.inAppNotificationData.notificationType === NOTIFICATION_TYPES.Vendor
    ) {
      navigate(`/vendor-profile/${n?.inAppNotificationData?.vendorId}`)
    } else if (
      n.inAppNotificationData.notificationType === NOTIFICATION_TYPES.CHAT
    ) {
      // socketClient &&
      //   socketClient.activeChat({ chatId: n.inAppNotificationData.chatId })
      navigate('/chats')
    } else if (
      n.inAppNotificationData.notificationType === NOTIFICATION_TYPES.PRODUCTION
    ) {
      navigate(
        `/view-request/${n?.inAppNotificationData?.addedByUserId}?requestId=${n?.inAppNotificationData?.requestId}`
      )
    } else if (
      n.inAppNotificationData.notificationType === NOTIFICATION_TYPES.STUDIO
    ) {
      navigate(
        `/view-request/${n?.inAppNotificationData?.addedByUserId}?requestId=${n?.inAppNotificationData?.requestId}`
      )
    }

    // if (n.inAppNotificationData.notificationType === NOTIFICATION_TYPES.CHAT) {
    //   socketClient &&
    //     socketClient.activeChat({ chatId: n.inAppNotificationData.chatId })
    //   navigate('/chats')
    // } else if (
    //   n.inAppNotificationData.subNotificationType === NOTIFICATION_TYPES.ORDER
    // ) {
    //   window.location.href = `/order-details/${n.inAppNotificationData.vendorOrderId}`
    // } else if (
    //   n.inAppNotificationData.subNotificationType ===
    //   NOTIFICATION_TYPES.VENDOR_CHANGEPASS
    // ) {
    //   navigate('/profile')
    // } else if (
    //   n.inAppNotificationData.subNotificationType ===
    //   NOTIFICATION_TYPES.VENDOR_DOC_EXPIRED
    // ) {
    //   navigate('/company')
    // }
  }
  const unreadCountShow =
    (data && data?.data.unreadCount && data?.data.unreadCount !== 0) || null

  return (
    <div className="notification_action">
      <IconButton onClick={handleClickNotice} className="NotificationBell">
        <img src={Images.BELL} alt={AIT().notifications} />
        {unreadCountShow && (
          <span className="NotificationCount">{data?.data.unreadCount}</span>
        )}
      </IconButton>
      {openPopUp && (
        <Menu
          id="notification"
          anchorEl={anchorEl}
          open={openPopUp}
          onClose={handleCloseNotice}
          MenuListProps={{
            'aria-labelledby': 'notification',
          }}
          className="notification_dropdown"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: -25,
            horizontal: 'right',
          }}
        >
          <Box className="notifications">
            <div className="noticeHeader">
              <h3>Notifications ({data && data.data.total})</h3>
              {data?.data && data?.data.unreadCount !== 0 ? (
                <CustomButton
                  size="large"
                  variant="text"
                  text={`Read All`}
                  showIcon={false}
                  width="auto"
                  type="button"
                  id="read_all"
                  className="read_all_btn"
                  onClick={() => {
                    handleCloseNotice()
                    markAllNotificatioRead(() => {
                      dispatch(fetchNoticationList({ page: 1, pageSize: 5 }))
                    })
                    setPageNumber(1)
                    dispatch(resetList())
                  }}
                />
              ) : (
                <></>
              )}

              <IconButton
                onClick={handleCloseNotice}
                className="close_notification"
              >
                <img src={Images.CLOSE} alt={AIT().close} />
              </IconButton>
            </div>
            {data &&
            data.data &&
            data?.data.data &&
            data?.data.data.length > 0 ? (
              <ul className="scroll-list" ref={boxRef}>
                {data.data.data.map((d: any) => (
                  <li
                    onClick={() => {
                      handleNotificationClick(d)
                    }}
                  >
                    <figure>
                      <img src={Images.BELL_WHITE} alt={AIT().notification} />
                    </figure>
                    <div className="notice_text">
                      <div className="title">
                        <h3>{d.inAppNotificationData.title}</h3>
                        <span>{dayjs(d.createdAt).fromNow(true)} ago</span>
                      </div>
                      <p>
                        {['PRODUCTION', 'STUDIO']?.includes(
                          d?.inAppNotificationData.notificationType
                        )
                          ? d?.message
                          : d.inAppNotificationData?.body}
                      </p>
                    </div>
                    <div className="unread">
                      {!d.isRead && (
                        <img
                          src={Images.UNREAD_NOTIFICATION}
                          alt="New message"
                        />
                      )}
                    </div>
                  </li>
                ))}
                {loading === 'pending' && (
                  <LoadingNotification isLoading={true} />
                )}
              </ul>
            ) : (
              <></>
            )}
            {loading === 'fulfilled' &&
              data &&
              data.data &&
              data?.data.data &&
              data?.data.data.length === 0 && <NoNotification />}
          </Box>
        </Menu>
      )}
    </div>
  )
}

export default InAppNotification
