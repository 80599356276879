import { createSlice } from '@reduxjs/toolkit';
import { resetAuthorizationToken, setAuthorizationToken } from '../api';
import { AuthState } from '../types';
import { getApiCall } from '../api/methods';
import endPoints from '../api/endpoint';
import { notify } from '../Utils/toastify';

const initialState: AuthState = {
  status: false,
  userData: null,
  token: null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const {
        payload: { userData, token },
      } = action;
      state.status = true;
      state.userData = userData;
      state.token = token;
      setAuthorizationToken(token);
      localStorage.setItem('token', token);
    },
    logout: (state) => {
      state.status = false;
      state.userData = null;
      state.token = null;
      if (localStorage.getItem('vendor_email')) {
        localStorage.removeItem('token');
      } else {
        localStorage.clear();
      }
      localStorage.removeItem('token');
      localStorage.setItem('d', '1');
      resetAuthorizationToken();
    },
    updateUserDetails: (state, action) => {
      // console.log('Update PRR =>', action)
      const {
        payload: { userData, token },
      } = action;
      state.userData = { ...state.userData, ...action.payload };
    },
    updateStep: (state, action) => {
      if (state.userData == null) {
        state.userData = { formNextStep: action.payload.step };
      } else {
        state.userData['formNextStep'] = action.payload.step;
      }
    },
    saveUserData: (state, action) => {
      // console.log(action)
      if (state.userData == null) {
        state.userData = { [action.payload.key]: action.payload.value };
      } else {
        state.userData[action.payload.key] = action.payload.value;
      }
    },
  },
});

export const { login, logout, updateStep, saveUserData, updateUserDetails } =
  authSlice.actions;
const handleError = (e: any) => {
  if (e?.data && e?.data.message) {
    notify(e?.data.message, 'error');
  } else {
    notify(null, 'error');
  }
};
export const updateDeviceTokenForSafari = (token: string) => {
  getApiCall(
    `${endPoints.notification.updateDeviceToken}?token=${token}`,
    (s: any) => {
      console.log(s);
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export default authSlice.reducer;
