import { combineReducers } from '@reduxjs/toolkit'
import auth from './authSlice'
import common from './commonSlice'
import order from './order'
import clientManagement from '../Pages/Clients/clientSlice'
import vendorSlice from '../Pages/VendorManagement/services/vendorSlice'
import profile from '../Pages/Profile/profileSlice'
import StaticContentSlice from '../Pages/StaticContent/services/staticSlice'
import users from '../Pages/Users/userManagementSlice'
import banners from '../Pages/Banner/bannerManagementSlice'
import products from '../Pages/ProductManagement/productSlice'
import productionSlice from '../Pages/ManageProduction/services/productionSlice'
import companyManagement from '../Pages/StudioManagement/companyManagementSlice'
import rolesManagement from '../Pages/RolesAndResponsibilities/rolesManagementSlice'
import requestManagementSlice from '../Pages/RequestManagement/services/requestSlice'
import { chatReducer } from '../Pages/Chats/chat.slice'
import commission from './commissionSlice'
import notification from './notification.slice'
import loaderReducer from './loaders.slice'

export const rootReducer = combineReducers({
  auth,
  common,
  clientManagement,
  vendor: vendorSlice,
  profile,
  staticContent: StaticContentSlice,
  users,
  commission,
  order,
  banners,
  products,
  production: productionSlice,
  companyManagement,
  rolesManagement,
  chat: chatReducer,
  requestManagement: requestManagementSlice,
  loaders: loaderReducer,
  notification,
})
