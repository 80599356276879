// apiSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../api'
import endPoints from '../api/endpoint'
import { apiSuccessCode, putApiCall } from '../api/methods'

interface ApiResponse {
  data: {
    data: any[]
    total: number
    pageNo: number
    totalPage: number
    nextHit: number
    limit: number
    unreadCount: number
  }
}

interface ApiState {
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  error: string | null
  data: ApiResponse | null
}

const initialState: ApiState = {
  loading: 'idle',
  error: null,
  data: null,
}

// Define an asynchronous thunk action creator
export const fetchNoticationList = createAsyncThunk<
  ApiResponse,
  { page: number; pageSize: number },
  { rejectValue: string }
>(
  'api/fetchApiNotifications',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await api.axiosInstance.get(
        endPoints.notification.notifications,
        {
          params: {
            pageNo: page,
            limit: pageSize,
          },
        }
      )
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
)

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetList: (state) => {
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNoticationList.pending, (state) => {
        state.loading = 'pending'
        state.error = null
      })
      .addCase(fetchNoticationList.fulfilled, (state, action) => {
        state.loading = 'fulfilled'
        state.error = null
        if (state.data === null || action.payload.data.pageNo === 1) {
          state.data = action.payload
        } else if (state.data.data) {
          action.payload.data.data = [
            ...state.data.data.data,
            ...action.payload.data.data,
          ]
          state.data = action.payload
        }
      })
      .addCase(fetchNoticationList.rejected, (state, action) => {
        state.loading = 'rejected'
        state.error = action.payload as string
        state.data = null
      })
  },
})

export const markNotificatioRead = (id: string, cb: any) => {
  putApiCall(
    `${endPoints.notification.notifications}/${id}`,
    { notificationId: id },
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (statusCode && statusCode === apiSuccessCode.created) {
        cb()
      }
    },
    (e: any) => {}
  )
}
export const markAllNotificatioRead = (cb: any) => {
  putApiCall(
    endPoints.notification.notifications,
    {},
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (statusCode && statusCode === apiSuccessCode.created) {
        cb()
      }
    },
    (e: any) => {}
  )
}

export const selectNotificationData = (state: any) => state?.notification?.data
export const selectNotificationLoading = (state: any) =>
  state.notification?.loading
export const selectNotificationApiError = (state: any) =>
  state.notification?.error

export default notificationSlice.reducer
export const { resetList } = notificationSlice.actions
