import AUTH_BG_1 from '../assets/images/auth-bg-1.png'
import LOGO from '../assets/images/logo.svg'
import HELP_CIRCLE from '../assets/images/help-circle.svg'
import USER_IMAGE from '../assets/images/user.png'
import CIRCLE_TICK from '../assets/images/tick-circle.svg'
import WELCOME_ILLUSTRATOR from '../assets/images/illustration.svg'
import SUCCESS_GIF from '../assets/images/aa.gif'
import CALENDAR from '../assets/images/calendar.svg'
import CHECK from '../assets/images/check-tick.svg'
import CHECK_TICK from '../assets/images/tick-square.svg'
import CHECK_RED from '../assets/images/tick-square-red.svg'
// import CHECK_TICK_RED from '../assets/images/check-tick-red.svg'
import ATTACHMENT from '../assets/images/attachment.svg'
import UNREAD_NOTIFICATION from '../assets/images/unread-notification.svg'

import WARNING from '../assets/images/warning.svg'
import CLOSE from '../assets/images/close.svg'
import LOADING from '../assets/images/loading.svg'
import DASHBOARD from '../assets/images/dashboard.svg'
import DASHBOARD_ACTIVE from '../assets/images/dashboard-active.svg'
import ORDERS from '../assets/images/orders.svg'
import ORDERS_ACTIVE from '../assets/images/orders-active.svg'
import VENDOR from '../assets/images/shop.svg'
import VENDOR_ACTIVE from '../assets/images/shop-active.svg'
import STATIC_CONTENT from '../assets/images/static-content.svg'
import STATIC_CONTENT_ACTIVE from '../assets/images/static-content-active.svg'
import FINANCIALS from '../assets/images/moneys.svg'
import FINANCIALS_ACTIVE from '../assets/images/moneys-active.svg'
import NOTIFICATION from '../assets/images/admin-notification.svg'
import NOTIFICATION_ACTIVE from '../assets/images/admin-notification-active.svg'
import BANNER from '../assets/images/gallery.svg'
import BANNER_ACTIVE from '../assets/images/gallery-active.svg'
import CLIENTS from '../assets/images/clients.svg'
import CHATS from '../assets/images/message.svg'
import CHATS_ACTIVE from '../assets/images/message-active.svg'
import CLIENTS_ACTIVE from '../assets/images/clients-active.svg'
import USER from '../assets/images/people.svg'
import USER_ACTIVE from '../assets/images/people-active.svg'
import NO_DATA from '../assets/images/no-data.svg'
import FILTER from '../assets/images/filter-outline.svg'
import FILE_ICON from '../assets/images/file.svg'
import DELETE_IC from '../assets/images/delete.svg'
import IMAGE_IC from '../assets/images/image_ic.svg'
import VIDEO_IC from '../assets/images/video.svg'
import VIDEO_IC_ACTIVE from '../assets/images/videoActive.svg'
import CANCEL_CIRCLE from '../assets/images/cancel-circle.svg'
import NO_DATA_FOUND from '../assets/images/no-data-found.svg'
import EDIT_IC from '../assets/images/edit-ic.svg'
import TRASH from '../assets/images/trash.svg'
import ADMIN_BG from '../assets/images/admin.png'
import LOGO_WHITE from '../assets/images/logo-white.svg'
import SUCCESS_IC from '../assets/images/success-ic.svg'
import ERROR_IC from '../assets/images/error-ic.svg'
import NO_RECORD_FOUND from '../assets/images/Illustrations.svg'
import PRODUCTION from '../assets/images/produnction.svg'
import PRODUCTION_ACTIVE from '../assets/images/produnction-active.svg'
import IMAGE_PLACEHOLDER from '../assets/images/image-placeholder.png'
import VISSIBILITY from '../assets/images/vissibility.svg'
import VISSIBILITY_OFF from '../assets/images/vissibility-off.svg'
import CLOSE_CIRCLE from '../assets/images/close-circle.svg'
import ARROW_DOWN from '../assets/images/arrow-down.svg'
import INFO_ICON from '../assets/images/info-icon.svg'
import ROLES from '../assets/images/roles.svg'
import ROLES_ACTIVE from '../assets/images/roles-active.svg'
import INFO_ICON_LIGHT from '../assets/images/info-icon-light.svg'
import DOCUMENT from '../assets/images/document.svg'
import QUESTION from '../assets/images/question.svg'
import REQUEST from '../assets/images/request.svg'
import REQUEST_ACTIVE from '../assets/images/request-active.svg'
import TICK_CIRCLE from '../assets/images/tick-circle-white.svg'
import PRODUCT_LIBRARY from '../assets/images/product-library.svg'
import PRODUCT_LIBRARY_ACTIVE from '../assets/images/product-library-active.svg'
import MAP_PIN from '../assets/images/map_pin.svg'
import CHECL_CTA from '../assets/images/check-cta.svg'
import CLOSE_CTA from '../assets/images/close-cta.svg'
import SEARCH from '../assets/images/search.svg'
import SEARCH_BLACK from '../assets/images/search-black.svg'
import CHAT from '../assets/images/chat.svg'
import DOWNLOAD_FILE from '../assets/images/download-file.svg'
import VENDOR_PLACEHOLDER from '../assets/images/vendor-placeholder.png'
import LOADER_TWO from '../assets/images/loader-2.gif'
import IC_CAMERA from '../assets/images/ic-camera.svg'
import IC_DOCUMENT from '../assets/images/ic-document.svg'
import IC_VIDEO from '../assets/images/ic-video.svg'
import IC_MEDIA from '../assets/images/ic-media.svg'
import SINGLE_TICK from '../assets/images/single-check.svg'
import DOUBLE_TICK from '../assets/images/double-check.svg'
import DOUBLE_TICK_UNREAD from '../assets/images/double-check-unread.svg'
import ONLINE from '../assets/images/online.svg'
import SEND from '../assets/images/send.svg'
import SORT from '../assets/images/sort.svg'
import SORT_ASCENDING from '../assets/images/ascending.svg'
import SORT_DESCENDING from '../assets/images/descending.svg'
import BLOCK_ICON from '../assets/images/block-icon.svg'
import VIEW from '../assets/images/view.svg'
import BELL from '../assets/images/notification-bing.svg'
import BELL_WHITE from '../assets/images/notification-white.svg'
import NO_NOTIFICATIONS from '../assets/images/no-notifications.svg'
import COMISSIONS from '../assets/images/commission.svg'
import COMISSIONS_ACTIVE from '../assets/images/commission-active.svg'
import CHIP_DELETE from '../assets/images/chip-delete.svg'

const Images = {
  VIDEO_IC_ACTIVE,
  AUTH_BG_1,
  LOADER_TWO,
  LOGO,
  HELP_CIRCLE,
  USER_IMAGE,
  CIRCLE_TICK,
  WELCOME_ILLUSTRATOR,
  SUCCESS_GIF,
  CALENDAR,
  CHECK,
  CHECK_TICK,
  ATTACHMENT,
  WARNING,
  CLOSE,
  CHECK_RED,
  CHATS,
  CHATS_ACTIVE,
  // CHECK_TICK_RED,
  LOADING,
  DASHBOARD,
  DASHBOARD_ACTIVE,
  ORDERS,
  ORDERS_ACTIVE,
  VENDOR,
  VENDOR_ACTIVE,
  STATIC_CONTENT,
  STATIC_CONTENT_ACTIVE,
  FINANCIALS,
  FINANCIALS_ACTIVE,
  NOTIFICATION,
  NOTIFICATION_ACTIVE,
  BANNER,
  BANNER_ACTIVE,
  CLIENTS,
  CLIENTS_ACTIVE,
  USER,
  USER_ACTIVE,
  NO_DATA,
  FILTER,
  FILE_ICON,
  DELETE_IC,
  IMAGE_IC,
  VIDEO_IC,
  NO_DATA_FOUND,
  EDIT_IC,
  TRASH,
  ADMIN_BG,
  LOGO_WHITE,
  SUCCESS_IC,
  ERROR_IC,
  NO_RECORD_FOUND,
  PRODUCTION,
  PRODUCTION_ACTIVE,
  IMAGE_PLACEHOLDER,
  VISSIBILITY,
  VISSIBILITY_OFF,
  CLOSE_CIRCLE,
  CANCEL_CIRCLE,
  ARROW_DOWN,
  INFO_ICON,
  ROLES,
  ROLES_ACTIVE,
  INFO_ICON_LIGHT,
  DOCUMENT,
  QUESTION,
  REQUEST,
  REQUEST_ACTIVE,
  TICK_CIRCLE,
  PRODUCT_LIBRARY,
  PRODUCT_LIBRARY_ACTIVE,
  NO_NOTIFICATIONS,
  MAP_PIN,
  CHECL_CTA,
  CLOSE_CTA,
  SEARCH,
  SEARCH_BLACK,
  CHAT,
  DOWNLOAD_FILE,
  VENDOR_PLACEHOLDER,
  IC_CAMERA,
  IC_DOCUMENT,
  IC_VIDEO,
  IC_MEDIA,
  SINGLE_TICK,
  DOUBLE_TICK,
  DOUBLE_TICK_UNREAD,
  ONLINE,
  SEND,
  SORT,
  SORT_ASCENDING,
  SORT_DESCENDING,
  BLOCK_ICON,
  VIEW,
  BELL_WHITE,
  BELL,
  COMISSIONS,
  COMISSIONS_ACTIVE,
  CHIP_DELETE,
  UNREAD_NOTIFICATION,
}

export default Images
